import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrinterSelectionDialog = _resolveComponent("PrinterSelectionDialog")!
  const _component_MessageBox = _resolveComponent("MessageBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_PrinterSelectionDialog, {
      visible: _ctx.showPickTicketPrinterDialog,
      heading: "Select Pick Ticket Printer",
      printers: _ctx.getPrinterQueues.serverPrinters,
      defaultPrinter: _ctx.getDefaultPickTicketPrinter,
      onOnPrintDialogPrint: _ctx.handlePrintPickTicket,
      onOnPrintDialogCancel: _cache[0] || (_cache[0] = ($event: any) => {_ctx.showPickTicketPrinterDialog = false; _ctx.cancel();})
    }, null, 8, ["visible", "printers", "defaultPrinter", "onOnPrintDialogPrint"]),
    _createVNode(_component_MessageBox, {
      showDialog: _ctx.showConfirmPrintPickTicketDialog,
      icon: "WARNING",
      header: "Warning",
      message: "A pick ticket for this order has already been printed. Would you like to print a new one?",
      primaryButton: "Yes",
      cancelButton: "No",
      onPrimaryButtonClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.showConfirmPrintPickTicketDialog = false; _ctx.showPickTicketPrinterDialog = true}),
      onCancelButtonClick: _cache[2] || (_cache[2] = ($event: any) => {_ctx.showConfirmPrintPickTicketDialog = false; _ctx.cancel();})
    }, null, 8, ["showDialog"])
  ], 64))
}