
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import EmailFileDialog from "@/components/UI/EmailFileDialog.vue";
import SalesOrder, { LisItems } from "@/types/salesorder";
import Pdf from "@/types/pdf";
import Button from 'primevue/button';
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Filter, { FilterConstraints } from "@/types/filter";
import MultiselectWithButton from "@/components/UI/MultiselectWithButton.vue";
import Dialog from "primevue/dialog";
import SalesDialog from "@/components/Sales/SalesDialog.vue";
import Search from "@/components/Search.vue";
import SalesService from "@/services/SalesService";
import CustomerService from "@/services/CustomerService";
import PrintPickTicket from "@/components/Pos/PrintPickTicket.vue";

// Services
const salesService = new SalesService();
const custService = new CustomerService();

export default defineComponent({
  name: "PosDataWrapper",
  components: {
    DataTable,
    InputText,
    Column,
    Calendar,
    LoadingSpinner,
    Card,
    Button,
    EmailFileDialog,
    MultiselectWithButton,
    Dialog,
    SalesDialog,
    Search,
    PrintPickTicket
  },
  async created() {
    if (this.getCustomer !== undefined) {
      this.selectedCustomer = this.getCustomer;
    } else {
      this.selectedCustomer = "";
    }

    if(!this.manuallyLoadOrders) {
      this.isLoadingSales = true;
      this.fetchOrdersData(false)
    }
    this.initFilters();    
    if(!this.getPrinterQueues.serverPrinters) {
      await this.getPrinterControl({client: this.getClient, refresh: true})
    }
  },
  mounted() {
    (this.$refs.search_order as any).$refs.autocomplete.$el.children[0].focus()
  },
  computed: {
    ...mapGetters({
      getOrder: "pos/getCurrentOrder",
      getCustomer: "pos/getCustomer",
      getClient: "session/getClient",
      getPDFs: "sales/getPDFS",
      getLoadingPDFs: "sales/getLoadingPDFs",
      getFdict: "fdict/getFdict",
      manuallyLoadOrders: "mrkControl/manuallyLoadOrders",
      hideOrderButtons: "mrkControl/hideOrderButtons",
      posShowEditOrder: "mrkControl/posShowEditOrder",
      getDefaultPickTicketPrinter: "pos/getDefaultPickTicketPrinter",
      getPrinterQueues: "printControl/getPrinterQueues",
      showPickTicketPrint: "mrkControl/showPickTicketPrint",
      getWebAccess: "session/getWebAccess",
    }),
    allowOrderToShip(): boolean {
      return this.getWebAccess("SHIP.E") && (this.salesOrder.status == 'N' || this.salesOrder.status == 'B')
    },
    salesItems(): any {
      const orderIds = this.getOrder.map((item: any) => item.so_id)
      const filteredItems = this.items.filter((item: any) => !orderIds.includes(item.so_id))
      return [...this.getOrder, ...filteredItems]
    }
  },
  data() {
    return {
      showPickTicketPrinterDialog: false,
      emptyTableLabel: 'Orders have not been loaded',
      items: [] as Array<SalesOrder>,
      selectedCustomer: "",
      dateStart: "",
      dateEnd: "",
      selectedSale: null,
      selectedRow: null,
      id: "",
      order: false,
      orderRecordsBy: "DEC",
      sortOrder: -1,
      sortField: "formatted_date",
      first: 0,
      rows: 10,
      page: 1,
      rangeStart: 1,
      rangeEnd: 100,
      selectedSalesID: "",
      isLoadingSales: false,
      visibleSalesActions: false,
      status: [
        { status: "New", initial: "N" },
        { status: "Back Order", initial: "B" },
        { status: "Closed", initial: "C" },
      ],
      statusLabel: {
        N: 'New',
        B: 'Back Order',
        C: 'Closed',
      },
      salesOrder: {} as SalesOrder,
      filters: {},
      loadingSalesOrderPDFs: [] as Array<string>,
      showEmailPDFDialog: false,
      email: "",
      orderIdToEmail: '',
      showSalesDialog: false,
      selectedFilterStatus: [] as any[]
    };
  },
  methods: {
    ...mapActions({
      addPartToOrder: "pos/addPartToOrder",
      setCustomer: "pos/setCustomer",
      setReg: "pos/setRegister",
      getOrderPDF: "sales/getOrderPDF",
      addNotification: "notification/add",
      setLastItemChanged: "pos/setLastItemChanged",
      setCustomerContacItems: 'pos/setCustomerContacItems',
      setStoredCartItems: "pos/setStoredCartItems",
      replaceOrder: "pos/replaceOrder",
      setSalesOrder: "pos/setSalesOrder",
      setDefaultPickTicketPrinter: "pos/setDefaultPickTicketPrinter",
      getPrinterControl: "printControl/getPrintControl",
      validateSalesOrder: "pos/validateSalesOrder",
    }),
    rowClick(event: any) {
      this.salesOrder = event.data;
      this.visibleSalesActions = true;
    },

    addSalesOrderToSummary() {
      if(this.getOrder.filter((item: any) => item.so_id === this.salesOrder.so_id).length == 0) {
         this.addPartToOrder(this.salesOrder)
      } else {
        this.setLastItemChanged('').then(() => {
          this.setLastItemChanged(this.salesOrder.so_id)
        })
      }
    },
    async addOrderToParts() {
      this.visibleSalesActions = false;

      await this.setSalesOrder({
          order: JSON.parse(JSON.stringify(this.salesOrder)),
          oldOrder: JSON.parse(JSON.stringify(this.salesOrder)),
          date_created: new Date(this.salesOrder.date),
          racks: []
      });
      this.validateSalesOrder({metaData: {validate_only: "Y", validate_reason: "CUSTOMER_SELECTED"}});
      this.$router.push("/pos/parts");
    },
    showOrderDialog() {
      this.showSalesDialog = true; 
      this.visibleSalesActions = false;
    },
    clearStatusSearchBar() {
      this.selectedFilterStatus = [];
      this.fetchOrdersData(false);
    },
    pageClick(event: any) {
      this.page = event.page;
      this.first = event.first;
      this.rows = event.rows;
      if (
        (event.page == event.pageCount ||
          event.page == event.pageCount - 1) &&
        this.items.length == this.rangeEnd
      ) {

        this.fetchOrdersData(true);
      }
    },
    sortData(event: any) {
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;
     
      switch(this.sortField) {
        case "so_id":
          this.sortField = "so.id";
          break;
        case "po_number":
          this.sortField = "po.number";
          break;
        case "cust_name":
          this.sortField = "cust.name";
          break;
      }

      this.order = this.order !== true;
      if (this.order == true) {
        this.sortOrder = 1;
        //blank string will cause api to sort by ascending order
        this.orderRecordsBy = "";
      } else {
        this.orderRecordsBy = "DEC";
        this.sortOrder = -1;
      }
      this.isLoadingSales = true;
      this.fetchOrdersData(false)
    },

    initFilters() {
      this.filters = {
          'so_id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
          'status': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
          'formatted_date': {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.BETWEEN }]},
          'cust_name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
      }
    },

    fetchOrdersData(addOrder: boolean) {
      this.isLoadingSales = true;
      if(addOrder) {
        this.rangeStart += 100
        this.rangeEnd += 100
      }else{
        this.rangeStart = 1
        this.rangeEnd = 100
      }

      let sortField = this.sortField
      if(this.sortField === 'formatted_date') {
         sortField = 'date'
      }

      salesService.getOrdersRecords(
        [((this.selectedCustomer as any)['cust_id'])],
        this.selectedSalesID,
        this.selectedFilterStatus,
        this.rangeStart,
        this.rangeEnd,
        sortField,
        this.orderRecordsBy,
        this.dateStart,
        this.dateEnd,
        "cust_name rep_name order_amount part_type po_status curr_avail",
      )
        .then((response:any) => {
          this.isLoadingSales = false;
          if(addOrder) {
            this.items.push(...response.so_items)
          } else {
            this.items = response.so_items || [];
          }
          this.items.forEach( item => {
            (item['formatted_date'] as any) = new Date(item.date);
          });
        })
        .catch(() => {
          this.isLoadingSales = false;
        })
        .finally(() => {
          // This message will be shown only if the user requested at least once the invoices and the list is empty  
          this.emptyTableLabel = 'No orders have been found';
        });
      if (!addOrder) {
        this.page = 1;
        this.first = 0;
      }
    },

    fetchFilteredOrders(filter: Filter, filterValue: string) {
      if (filter.field == "so_id") {
        this.selectedSalesID = filterValue;
        this.fetchOrdersData(false) 
      } else if (filter.field == "date") {
        if (filterValue[0] != null) this.dateStart = this.formatStringDate(filterValue[0]);
        if (filterValue[1] != null) this.dateEnd = this.formatStringDate(filterValue[1]);

        this.fetchOrdersData(false);
      } else if (filter.field == "cust_name") {
        this.selectedCustomer = filterValue;
        this.fetchOrdersData(false);
      }
      this.page = 1
      this.first = 0;
    },
    formatStringDate(dueDate: string) {
      const reformatedDueDate = new Date(dueDate)
        ?.toLocaleString("en-US", {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        })
        .split("/")
        .join("-");

      return reformatedDueDate
    },
    getSalesOrderPDF(id: string) {
      this.getOrderPDF({client: this.getClient, recordId: id});
    },
    salePDFIcon(id: string) {
      let downloaded: Array<Pdf> = this.getPDFs
      let downloading: boolean = this.getLoadingPDFs.find((i: string) => i === id) !== undefined
      return {
        "pi pi-download":
          downloaded.find((i: Pdf) => i.id === id) === undefined &&
          !downloading,
        "pi pi-spin pi-spinner": downloading,
        "pi pi-file-pdf":
          downloaded.find((i: Pdf) => i.id === id) !== undefined &&
          !downloading,
      };
    },
    handleEmailPDF(orderId: string) {
      this.orderIdToEmail = orderId;
      this.showEmailPDFDialog = true;
    },
    showPickTicketDialog(order: any) {
      this.showPickTicketPrinterDialog = true;
      this.salesOrder = order;
    },
    handleCloseEmailDialog() {
      this.showEmailPDFDialog = false;
      this.email = "";
      this.orderIdToEmail = '';
    }, 
    lookupSalesOrder(input: string, searchComponent: any) {
      if (input) {
        salesService.getOrders(
          this.getCustomer.cust_id,
          input,
          [] as any,
          "cust_name rep_name order_amount",
          "",
          "SO"
        ).then((response: any) => {
          if(response.so_items?.length > 0) {
            const order = response.so_items[0];
            if(order.status === 'C') {
              this.addNotification({
                message: `Sales Order #${input} is closed.`,
                type: "error",
              });
            } else if(order.sold_to != this.getCustomer.cust_id && order.cust_id != this.getCustomer.cust_id) {
              this.addNotification({
                message: `Sales Order #${input} is not associated with this customer.`,
                type: "error",
              });
            }
            this.addPartToOrder(response.so_items[0])
          } else {
            this.addNotification({
              message: `Sales Order #${input} was not found.`,
              type: "error",
            });
          }
        }).finally(() => {
          (searchComponent.$refs.autocomplete).searching = false;
          (searchComponent.$refs.autocomplete).$el.children[0].blur();
          (searchComponent.$refs.autocomplete).$el.children[0].focus();
        })
      }
    },
    sendEmail(data: any) {
      salesService.getOrderPDF(this.orderIdToEmail, this.getClient, data)
      .then((response) => {
          if(response === 'success') {
            this.addNotification({
              message: `Sales Order has been emailed successfully`,
              type: "success",
            });
          }
          else {
            this.addNotification({
              message: `Sales Order was not sent`,
              type: "error",
            });
          }
        }).catch((error) => {
          this.addNotification({
            message: `Sales Order could not be sent: ${error}`,
            type: "error",
          });
        })
    },
    isSelectedRow(data: any) {
      return this.getOrder.find((item: any) => item.so_id === data.so_id) && 'bg-blue-100';
    },
    handleAddressAdded() {
      custService.getCustomer(this.getCustomer.cust_id, this.getClient, "contact_email contact_name").then((response: any) => {
        this.setCustomerContacItems(response.contact_id_items);
      })
    },
  },
});
